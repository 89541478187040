import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import { CSSProperties } from "react";

interface ILinkProps {
  to: string;
  internal?: boolean;
  children: React.ReactNode;
  openNewTab?: boolean;
  className?: string;
  style?: CSSProperties;
  state?: Object;
  partiallyActive?: boolean;
  onClick?: Function;
}

const Link: React.FC<ILinkProps> = ({
  to = "#",
  internal = true,
  children,
  openNewTab = false,
  className,
  style,
  state,
  onClick
}) => {
  const commonClassName = "text-gray-400 hover:text-white";

  if (internal) {
    return (
      <GatsbyLink
        to={to}
        className={`${className}`}
        style={style}
        target={openNewTab ? "_blank" : ""}
        rel={openNewTab ? "noopener noreferrer" : ""}
        state={state}
        onClick={mea => {
          if (onClick !== undefined) onClick();
        }}
      >
        {children}
      </GatsbyLink>
    );
  } else {
    return (
      <a
        href={to}
        target={openNewTab ? "_blank" : ""}
        rel={openNewTab ? "noopener noreferrer" : ""}
        className={`${className}`}
        style={style}
        onClick={mea => {
          if (onClick !== undefined) onClick();
        }}
      >
        {children}
      </a>
    );
  }
};

export default Link;
