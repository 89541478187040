import React, { ReactNode, useEffect, useState } from "react";
import "../../css/tailwind.css";
import "../../css/custom.css";
import Img from "gatsby-image";

import Navbar from "./navbar";
import Footer from "./footer";
import Selector from "./selector";
import { graphql, useStaticQuery, navigate } from "gatsby";

interface Props {
  children: ReactNode;
}

const Root: React.FC<Props> = ({ children }) => {
  const headerHeight = 170;
  const [sticky, setSticky] = useState(false);
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "site-images/logo.png" }) {
        childImageSharp {
          fixed(width: 200, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  function handleScroll() {
    if (window.pageYOffset >= headerHeight) {
      setSticky(true);
    } else if (window.pageYOffset < headerHeight) {
      setSticky(false);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div ref={el => el?.style.setProperty("overflow-x", "hidden", "important")}>
      <div
        className="flex justify-center items-center bg-tertiary-700"
        style={{ height: headerHeight }}
        onClick={() => {
          navigate("/");
        }}
      >
        <Img
          className="object-contain"
          fixed={data.file.childImageSharp.fixed}
        />
      </div>
      <Navbar sticky={sticky} />
      {children}
      <Footer />
    </div>
  );
};

export default Root;
