import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import Link from "../controls/link";
import FacebookIcon from "../icons/facebook-icon";
import InstagramIcon from "../icons/instagram-icon";
import MenuIcon from "../icons/menu-icon";
import { useState } from "react";

interface Props {
  sticky?: boolean;
}

interface PageQuery {
  site: {
    siteMetadata: {
      address: string;
      phone: string;
      email: string;
      socialNetworks: {
        name: string;
        url: string;
      }[];
    };
  };
  sections: {
    distinct: string[];
  };
}

const Navbar: React.FC<Props> = ({ sticky = false }) => {
  const { site, sections } = useStaticQuery<PageQuery>(graphql`
    {
      site {
        siteMetadata {
          address
          phone
          email
          socialNetworks {
            name
            url
          }
        }
      }
      sections: allInventoryJson {
        distinct(field: section)
      }
    }
  `);

  const routes = [
    {
      label: "Home",
      to: "/"
    },
    ...sections.distinct.map((section: string) => {
      return {
        label: section,
        to: `/stock?section=${section}`
      };
    }),
    {
      label: "Encomendar",
      to: "/order"
    },
    {
      label: "Videos",
      to: "/videos"
    },
    {
      label: "Contactos",
      to: "/about-us"
    }
  ];

  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <nav
        className={`bg-tertiary-700 text-primary-100 z-50 ${
          sticky ? "fixed top-0 w-full" : ""
        }`}
      >
        <div
          className={`container flex flex-col lg:flex-row ${
            expanded ? "h-8navbar" : "h-navbar"
          } overflow-hidden transition-height duration-150 ease-in-out`}
        >
          <div
            key="logo-div"
            className="flex flex-1 items-center justify-start py-3"
          >
            <Link
              className="mr-4 flex-1 lg:flex-none text-2xl uppercase font-semibold pl-4 lg:pl-0"
              key={"go-home"}
              to={"/"}
            >
              Stand{" "}
              <span className="text-highlight-500 font-extrabold italic">
                Carvalho
              </span>
            </Link>
            <button
              onClick={() => setExpanded(!expanded)}
              aria-label="Abrir menu"
            >
              <MenuIcon className="lg:hidden mx-4 py-1 text-primary-100 hover:text-highlight-500 transition-colors duration-150 ease-in-out" />
            </button>
            <Link
              className="hidden xl:block cursor-pointer mr-2 ml-2 text-primary-100 hover:text-highlight-500 transition-colors duration-150 ease-in-out"
              to={
                site.siteMetadata.socialNetworks.find(
                  x => x.name === "facebook"
                )?.url || "#"
              }
              openNewTab={true}
              internal={false}
            >
              <FacebookIcon className="w-8 h-8" />
            </Link>{" "}
            <Link
              className="hidden xl:block cursor-pointer mr-2 ml-2 text-primary-100 hover:text-highlight-500 transition-colors duration-150 ease-in-out"
              to={
                site.siteMetadata.socialNetworks.find(
                  x => x.name === "instagram"
                )?.url || "#"
              }
              openNewTab={true}
              internal={false}
            >
              <InstagramIcon className="w-8 h-8" />
            </Link>
          </div>
          {routes?.map((route, i) => {
            return (
              <Link
                onClick={() => {
                  setExpanded(false);
                }}
                key={i}
                to={route.to as string}
                className="h-navbar px-5 py-5 text-lg text-primary-100 border-none hover:border-solid border-b-4 border-highlight-500 hover:bg-tertiary-800 transition-colors duration-100 ease-in-out"
              >
                {route.label}
              </Link>
            );
          })}
        </div>
      </nav>
      <div className={`${sticky ? "h-navbar" : ""}`} />
    </>
  );
};

export default Navbar;
