import React from "react";

interface ISectionProps {
  children?: React.ReactNode;
  className?: String;
  container?: boolean;
  style?: any;
}

const Section: React.FC<ISectionProps> = ({
  children,
  className = "",
  container,
  style
}) => {
  return (
    <section
      className={`${container ? "container" : ""} ${className}`}
      style={style ? style : {}}
    >
      {children}
    </section>
  );
};

export default Section;
