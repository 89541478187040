import * as React from "react";
import Section from "./section";
import Input from "../controls/input";
import Button from "../controls/button";
import { graphql, useStaticQuery } from "gatsby";
import Heading from "../typography/heading";

import Link from "../controls/link";

import { SocialLogos } from "../logo";

const Footer: React.FC = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          address
          phone
          email
          socialNetworks {
            name
            url
          }
        }
      }
    }
  `);

  const contacts = [
    {
      label: "E-mail",
      value: (
        <a href={`mailto:${site.siteMetadata.email}`}>
          {site.siteMetadata.email}
        </a>
      )
    },
    {
      label: "Morada",
      value: site.siteMetadata.address
    },
    {
      label: "Telefone",
      value: site.siteMetadata.phone
    }
  ];

  const networks = site.siteMetadata.socialNetworks.map(
    (item: any, index: any) => {
      return (
        <Link
          key={index}
          className="cursor-pointer mr-4"
          to={item.url}
          openNewTab={true}
          internal={false}
        >
          {SocialLogos.find(element => element.name === item.name)?.value}
        </Link>
      );
    }
  );

  return (
    <Section className="bg-tertiary-700 py-10">
      <div className="container flex flex-col lg:flex-row">
        <div className="flex-1 py-4 px-4 lg:px-0 lg:py-0 lg:pr-4">
          <Heading className="text-center">Horário</Heading>
          <div className="flex justify-center">
            <table className="border-0 table-row text-primary-100 text-md whitespace-no-wrap">
              <thead>
                <tr>
                  <td>
                    <strong className="text-xs">
                      *Damos primazia ao atendimento por marcação
                    </strong>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>Segunda:</p>
                  </td>
                  <td>09:00-19:00</td>
                </tr>
                <tr>
                  <td>Terça:</td>
                  <td>09:00-19:00</td>
                </tr>
                <tr>
                  <td>Quarta:</td>
                  <td>09:00-19:00</td>
                </tr>
                <tr>
                  <td>Quinta:</td>
                  <td>09:00-19:00</td>
                </tr>
                <tr>
                  <td>Sexta:</td>
                  <td>09:00-19:00</td>
                </tr>
                <tr>
                  <td>Sábado:</td>
                  <td>09:00-16:00*</td>
                </tr>
                <tr>
                  <td>Domingo:</td>
                  <td>Marcações*</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="flex-1 py-4 px-4 lg:px-0 lg:py-0 lg:pl-4">
          <Heading className="text-center">Contactos</Heading>
          <div className="flex justify-center text-primary-100">
            <div className="w-1/4 flex flex-col font-semibold">
              {contacts.map((contact, index) => {
                return (
                  <span key={index} className="mb-2">
                    {contact.label}
                  </span>
                );
              })}
            </div>
            <div className="flex-1 flex flex-col">
              {contacts.map((contact, index) => {
                return (
                  <span key={index} className="mb-2">
                    {contact.value}
                  </span>
                );
              })}
            </div>
          </div>
        </div>
        <div className="flex-1 py-4 px-4 lg:px-0 lg:py-0 lg:pr-4">
          <Heading className="text-center">Conecte-se conosco</Heading>
          <div className="flex flex-row mt-2 justify-center">
            {networks.map((item: any, i: number) => {
              return (
                <span
                  key={i}
                  className="w-12 mr-4 text-primary-100 hover:text-highlight-500 transition-colors duration-150 ease-in-out"
                >
                  {item}
                </span>
              );
            })}
          </div>
          <div className="text-center">
            <Link
              to="https://standcarvalho.standvirtual.com/"
              internal={false}
              openNewTab={true}
              className="mt-5"
              style={{
                background:
                  "url(https://carspt-staticstmp.akamaized.net/packed/font/2fea0e82a7918be7c05e03ac8807ee5c24.svg) no-repeat",
                backgroundSize: "100% 100%",
                display: "inline-block",
                height: " 40px",
                overflow: "hidden",
                position: "relative",
                verticalAlign: "middle",
                zIndex: 1,
                width: "202px"
              }}
            >
              {null}
            </Link>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Footer;
