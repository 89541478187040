import React from "react";

interface IHeadingProps {
  children?: React.ReactNode;
  className?: string;
}

const Heading: React.FC<IHeadingProps> = ({ children, className = "" }) => {
  return (
    <div className={className}>
      <h1
        className={`inline-block text-2xl text-primary-100 font-semibold mb-6 ${className}`}
      >
        {children}
      </h1>
    </div>
  );
};

export default Heading;
